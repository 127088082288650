let url;

// url = 'http://localhost:3001'
url = 'https://violin-studio-backend.herokuapp.com'

const state = {
    hand: new Map(),
    serverUrl: url
}

export default state;
